.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-30 {
    font-size: 30px;
}

.colour-primary-on-hover {
    &:hover {
        color: var(--colour-primary) !important;
    }
}

.colour-secondary-on-hover {
    &:hover {
        color: var(--colour-secondary);
    }
}

.bg-colour-primary-on-hover {
    &:hover {
        background-color: var(--colour-primary);
    }
}

.bg-colour-highlight-1 {
    background-color: var(--colour-highlight-1);
}

.colour-white {
    color: white;
}

.colour-text-normal {
    color: var(--text-normal);
}

.colour-error {
    color: var(--text-error);
}

.colour-success {
    color: var(--text-success);
}

.colour-text-muted {
    color: var(--text-muted);
}

.colour-primary {
    color: var(--colour-primary);
}

.colour-secondary {
    color: var(--colour-secondary) !important;
}

.colour-highlight-1 {
    color: var(--colour-highlight-1);
}

.fw-boldest {
    font-weight: 800;
}

.font-family-open-sans {
    font-family: var(--open-sans);
}

.background-colour-white {
    background: var(--white);
}

.cursor_pointer {
    cursor: pointer;
}

.overflow_break_word {
    overflow-wrap: break-word;
}
