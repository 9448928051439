@import "./composition.scss";
@import "./utilities.scss";
@import "./blocks.scss";
@import "./react-mentions.scss";

body {
    color: var(--colour-primary);
    font-family: var(--asap);
    background: linear-gradient(
        240.3deg,
        rgba(248, 218, 239, 1) -17.93%,
        rgba(196, 196, 196, 0) 100.75%
    );
    min-height: 100vh;
}

h1 {
    font-family: var(--open-sans);
    font-weight: 800;
}

hr {
    border-top: 1px solid var(--colour-secondary);
}
