.login-page-elle-image {
    width: 100%;
    max-height: calc(100vh - 50px);

    @media only screen and (max-width: 576px) {
        max-width: 250px;
    }
}

.snap-text {
    line-height: 22px;
}

.trash-button {
    all: unset;
    height: 30px;
    width: 30px;
    fill: #838385;
}

.my-clickable-nav-item {
    border: none;
    background-color: transparent;
}

.styled-horizontal-rule {
    width: 100%;
    height: 0px;
    left: 37px;
    top: 748px;

    border: 1px solid var(--colour-primary);
}

.white-borer {
    border-color: white;
}

.elle-image {
    max-height: 250px;
    max-width: 100%;
}

.mini-elle-image {
    height: 66px;
    width: auto;
    vertical-align: text-bottom;
    margin-right: 19px;
}

.file-upload-wrapper {
    position: relative;
    overflow: hidden;
    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        cursor: inherit;
        display: block;
    }
}

.login-box {
    min-height: 100vh;
    max-width: 800px;
    margin: 0 auto;

    @media only screen and (max-width: 576px) {
        max-width: 350px;
    }
}

.new-cup-button {
    &:focus {
        background-color: var(--colour-primary);
        box-shadow: none;
    }
    &:disabled {
        background-color: var(--our-grey);
    }
}

.button-secondary {
    text-align: center;
    height: 30px;
    font-family: var(--open-sans);
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    border-color: var(--colour-highlight-2);
    border-width: 2px;
    border-style: outset;
    background-color: var(--colour-highlight-2);
    color: var(--white);
    transition: transform 0.5s;
    transform: none;
    padding: 1px 6px;

    &:hover {
        color: var(--colour-primary);
        border-color: var(--colour-primary);
    }
    &:disabled {
        color: var(--our-grey);
    }
}

.pops {
    &.success {
        transform: scale(1.1) translate(0, -10px);
        transition: transform 0.5s;
    }
}

.special-download-button {
    all: unset;
    img {
        height: 30px;
        width: auto;
        margin: 0;
        padding: 0;
    }
    fill: var(--colour-primary);

    &:hover {
        fill: var(--colour-secondary);
    }
}

@keyframes successExpandAnimation {
    0% {
        opacity: 0;
        transform: rotate(0deg) scaleX(1) scaleY(1);
    }
    50% {
        opacity: 1;
        transform: rotate(0deg) scaleX(1.1) scaleY(1.1);
    }
    100% {
        opacity: 0;
        transform: rotate(0deg) scaleX(1) scaleY(1);
    }
}

@keyframes wobbleAnimation {
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.snap-it-button {
    background: var(--colour-highlight-2);
    border: 2px solid var(--colour-highlight-2);

    &.success {
        animation: successExpandAnimation 1s linear;
    }

    &.error {
        animation: wobbleAnimation 1s linear;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: var(--colour-primary);
        border: 2px solid var(--colour-highlight-3);
    }
}

@keyframes grow-on-hover {
    20% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1.1);
    }
}

.grow-on-hover:hover {
    animation: grow-on-hover 1s;
    transform: scale(1.1);
}
