.single-column {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * {
        margin-top: 20px;
    }
}

.flow > * + * {
    margin-top: var(--flow-space, 1em);
}

.stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: stretch;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-content: flex-start;
    }

    & > * {
        margin: 1px 5px;
        flex: 1 0 0;
        @include media-breakpoint-up(md) {
            flex: 1 1 155px;
        }
    }
}
