// Set out custom colours
$primary: #a10970;
$secondary: #c76ba9;
$colour-highlight-1: #d640a6;
$colour-highlight-2: #e87ec6;
$colour-highlight-3: #f8daef;
$colour-muted: #97959c;

// Place bootstrap customisations below
@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

@import "bootstrap/scss/utilities"; // Required

:root {
    --open-sans: "Open Sans", sans-serif;
    --asap: "Asap", sans-serif;
    --colour-primary: #{$primary};
    --colour-secondary: #{$secondary};
    --our-grey: #838385;
    --text-main: #3d3d3d;
    --text-muted: #{$colour-muted};
    --text-normal: #faf8f8;
    --text-success: #b6faa2;
    --text-error: #fc4528;
    --colour-highlight-1: #{$colour-highlight-1};
    --colour-highlight-2: #{$colour-highlight-2};
    --colour-highlight-3: #{$colour-highlight-3};
    --danger-button: #d11121;
    --danger-button-hover: #520015;
    --white: #ffffff;
}

$font-family-base: var(--open-sans);

$table-color: $primary;
$table-border-color: $secondary;

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// @import "bootstrap/scss/root"; // Required
// @import "bootstrap/scss/reboot"; // Required
// @import "bootstrap/scss/type";
// // @import "bootstrap/scss/images";
// // @import "bootstrap/scss/code";
// @import "bootstrap/scss/containers";
// @import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// // @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
// // @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// // @import "bootstrap/scss/input-group"; // Requires forms
// // @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// // @import "bootstrap/scss/breadcrumb";
// // @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// // @import "bootstrap/scss/jumbotron";
// // @import "bootstrap/scss/alert";
// // @import "bootstrap/scss/progress";
// // @import "bootstrap/scss/media";
// // @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// // @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal"; // Requires transitions
// // @import "bootstrap/scss/tooltip";
// // @import "bootstrap/scss/popover";
// // @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/utilities";
// // @import "bootstrap/scss/print";

@import "./style";
